
























































































































import { Component, Vue } from "vue-property-decorator";
import { ayuntamiento } from "@/shared/dtos/ayuntamiento";
import { MessageService } from "@/shared/services/message-service";
import ayuntamientoModule from "@/store/modules/ayuntamiento-module";
import comunidad_autonomaModule from "@/store/modules/comunidad_autonoma-module";
import { singleOrDoubleRowClick } from "@/utils/dblclickrow";
import { comunidad_autonoma } from "@/shared/dtos/comunidad_autonoma";
@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class AyuntamientosLista extends Vue {
  public search: string = "";
  public show_dialog: boolean = false;
  public obj: ayuntamiento = new ayuntamiento();
  public es_edicion: boolean = false;
  public id_comunidad = 1;
  public created() {
    ayuntamientoModule.getayuntamientos();
    comunidad_autonomaModule.getcomunidad_autonomas_con_provincias();
  }

  public get comunidades() {
    return comunidad_autonomaModule.comunidad_autonomas;
  }

  public get provincias() {
    return comunidad_autonomaModule.comunidad_autonomas.find(
      (x) => x.id === this.id_comunidad
    )!.provincias;
  }

  public get datasource() {
    return ayuntamientoModule.ayuntamientos;
  }

  public get headers() {
    return [
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
      },
      { text: "", value: "acciones", align: "end" },
    ];
  }

  /**
   * editar
   */
  public editar_rw(obj: ayuntamiento) {
    this.obj = new ayuntamiento(obj);
    if (this.obj.id_provincia === undefined || this.obj.id_provincia === 0) {
      this.id_comunidad = 1;
    } else {
      this.id_comunidad = this.comunidades.find((x) =>
        x.provincias.find((k) => k.id === this.obj.id_provincia)
      )!.id;
    }
    this.es_edicion = true;
    this.show_dialog = true;
  }

  /**
   * add_new
   */
  public add_new() {
    this.$validator.reset();
    this.show_dialog = true;
  }
  /**
   *eliminar
   */
  public eliminar(obj: ayuntamiento) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar " + obj.nombre + "?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          ayuntamientoModule.eliminarayuntamiento(obj);
        }
      }
    );
  }

  /**
   * cancelar
   */
  public cancelar() {
    this.show_dialog = false;
    this.obj = new ayuntamiento();
    this.es_edicion = false;
  }

  /**
   * aceptar
   */
  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.es_edicion) {
          ayuntamientoModule.modificarayuntamiento(this.obj);
        } else {
          ayuntamientoModule.guardarayuntamiento(this.obj);
        }
        this.cancelar();
      }
    });
  }
  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
}
