import { store } from "@/store/store";
import { ayuntamiento } from "@/shared/dtos/ayuntamiento";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "ayuntamientoModule",
  store,
  dynamic: true,
})
class ayuntamientoModule extends VuexModule {
  public ayuntamientos: ayuntamiento[] = [];
  public ayuntamiento: ayuntamiento = new ayuntamiento();

  @Action({ commit: "onGetayuntamientos" })
  public async getayuntamientos() {
    return await ssmHttpService.get(API.ayuntamiento);
  }

  @Action({ commit: "onGetayuntamiento" })
  public async getayuntamiento(id: any) {
    return await ssmHttpService.get(API.ayuntamiento + "/" + id);
  }

  @Action
  public async guardarayuntamiento(ayuntamiento: ayuntamiento) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.ayuntamiento, ayuntamiento.toJson());
    this.getayuntamientos();
  }

  @Action
  public async modificarayuntamiento(ayuntamiento: ayuntamiento) {
    await ssmHttpService.put(
      API.ayuntamiento + "/" + ayuntamiento.id,
      ayuntamiento
    );
    this.getayuntamientos();
  }

  @Action
  public async eliminarayuntamiento(ayuntamiento: ayuntamiento) {
    await ssmHttpService.delete(
      API.ayuntamiento + "/" + ayuntamiento.id,
      null,
      false
    );
    this.getayuntamientos();
  }

  @Mutation
  public onGetayuntamientos(res: ayuntamiento[]) {
    this.ayuntamientos = res ? res.map((x) => new ayuntamiento(x)) : [];
  }

  @Mutation
  public onGetayuntamiento(res: ayuntamiento) {
    this.ayuntamiento = new ayuntamiento(res);
  }
}
export default getModule(ayuntamientoModule);
