import { store } from '@/store/store';
import { comunidad_autonoma } from '@/shared/dtos/comunidad_autonoma';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'comunidad_autonomaModule',
    store,
    dynamic: true
})
class comunidad_autonomaModule extends VuexModule {
    public comunidad_autonomas: comunidad_autonoma[] = [];
    public comunidad_autonoma: comunidad_autonoma = new comunidad_autonoma();

    @Action({ commit: 'onGetcomunidad_autonomas' })
    public async getcomunidad_autonomas() {
        return await ssmHttpService.get(API.comunidad_autonoma);
    }

    @Action({ commit: 'onGetcomunidad_autonomas' })
    public async getcomunidad_autonomas_con_provincias() {
        return await ssmHttpService.get(API.comunidad_autonoma+'/agrupado');
    }


    @Action({ commit: 'onGetcomunidad_autonoma' })
    public async getcomunidad_autonoma(id: any) {
        return await ssmHttpService.get(API.comunidad_autonoma + '/' + id);
    }

    @Action
    public async guardarcomunidad_autonoma(comunidad_autonoma: comunidad_autonoma) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.comunidad_autonoma, comunidad_autonoma.toJson());
        this.getcomunidad_autonomas();
    }

    @Action
    public async modificarcomunidad_autonoma(comunidad_autonoma: comunidad_autonoma) {
        await ssmHttpService.put(API.comunidad_autonoma, comunidad_autonoma);
        this.getcomunidad_autonomas();
    }

    @Action
    public async eliminarcomunidad_autonoma(comunidad_autonoma: comunidad_autonoma) {
        await ssmHttpService.delete(API.comunidad_autonoma + '/' + comunidad_autonoma.id, null, false);
        this.getcomunidad_autonomas();
    }

    @Mutation
    public onGetcomunidad_autonomas(res: comunidad_autonoma[]) {

        this.comunidad_autonomas = res ? res.map((x) => new comunidad_autonoma(x)) : []
    }

    @Mutation
    public onGetcomunidad_autonoma(res: comunidad_autonoma) {
        this.comunidad_autonoma = new comunidad_autonoma(res);
    }

}
export default getModule(comunidad_autonomaModule);